<template>
  <div class="view pa24">
    <el-button
      class="mr10"
      type="primary"
      v-if="jurisdictionA  || userInfo.roleId || userInfo.main"
      @click="
        centerDialogVisible = true;
        bdetermine = true;
        editUpdate = false
      "
      >添加职位</el-button
    >
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column align="center" type="index" label="序号" />
        <el-table-column prop="position" align="center" label="职位名称" />
        <el-table-column prop="recruitAddress" align="center" label="招聘城市" />
        <el-table-column prop="experienceStr"  align="center" label="工作经验" />
        <el-table-column prop="educationStr" align="center" label="学历要求" />
        <el-table-column prop="salary" align="center" label="薪资" />
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="
                edit(scope.row, false);
                bEdit = true;
              "
              >详情</el-button
            >
            <el-button
              type="text"
              @click="
                edit(scope.row, true);
                bEdit = false;
                editUpdate = true
              "
              >编辑</el-button
            >
            <!-- <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @onConfirm="del(scope.row.videoId)"
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？"
            >
              <el-button
                slot="reference"
                class="ml10"
                style="color: #fd634e"
                type="text"
                >删除</el-button
              >
            </el-popconfirm> -->
            <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="del(scope.row.recruitId)"
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？">
              <el-button
                  slot="reference"
                  class="ml10"
                  style="color: #fd634e"
                  type="text"
                  >删除</el-button
                >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      title="添加职位"
      :visible.sync="centerDialogVisible"
      @close="close"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      width="50%"
      center
      v-if="centerDialogVisible"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="78px"
      >
        <el-form-item label="职位名称" prop="position">
          <el-input v-model="ruleForm.position"></el-input>
        </el-form-item>
        <el-form-item label="招聘城市" prop="recruitAddress">
          <el-input v-model="ruleForm.recruitAddress"></el-input>
          <!-- <el-input id="suggestId" v-model="ruleForm.recruitAddress"></el-input>
          <div id="baidumap"></div> -->
        </el-form-item>
        <el-form-item label="工作经验" prop="experience">
         <el-select v-model="ruleForm.experienceStr" placeholder="不限">
        <el-option v-for="item in experienceArray" :key="item.value" :value="item.value" :label="item.label"></el-option>
       </el-select>
            <!-- <el-select
              v-model="ruleForm.experience"
              style="width: 240px"
            > -->
             <!-- <el-option label="全部" value="7"></el-option>
             <el-option label="应届" value="1"></el-option>
             <el-option label="1年内" value="2"></el-option>
             <el-option label="1-3年" value="3"></el-option>
             <el-option label="3-5年" value="4"></el-option>
             <el-option label="5-10年" value="5"></el-option>
             <el-option label="10年以上" value="6"></el-option> -->
            <!-- </el-select> -->
          </el-form-item>
        <el-form-item label="学历要求" prop="education">
            <el-select
              v-model="ruleForm.educationStr"
              style="width: 240px"
            >
            <el-option v-for="(item,index) in educationArray" :key="index" :label="item.name" :value="item.value"></el-option>
             <!-- <el-option label="全部" value="1"></el-option>
             <el-option label="初中及以下" value="2"></el-option>
             <el-option label="中专/中技" value="3"></el-option>
             <el-option label="高中" value="4"></el-option>
             <el-option label="大专" value="5"></el-option>
             <el-option label="本科" value="6"></el-option>
             <el-option label="硕士" value="7"></el-option>
             <el-option label="博士" value="8"></el-option> -->
            </el-select>
          </el-form-item>
        <el-form-item label="薪资" prop="min_salary">
            <el-select
              v-model="ruleForm.minSalary"
              style="width: 100px"
            >
             <el-option label="3" value="3"></el-option>
             <el-option label="4" value="4"></el-option>
             <el-option label="5" value="5"></el-option>
             <el-option label="6" value="6"></el-option>
             <el-option label="7" value="7"></el-option>
             <el-option label="8" value="8"></el-option>
             <el-option label="9" value="9"></el-option>
             <el-option label="10" value="10"></el-option>
             <el-option label="11" value="11"></el-option>
             <el-option label="12" value="12"></el-option>
             <el-option label="13" value="13"></el-option>
             <el-option label="14" value="14"></el-option>
             <el-option label="15" value="15"></el-option>
             <el-option label="16" value="16"></el-option>
             <el-option label="17" value="17"></el-option>
             <el-option label="18" value="18"></el-option>
             <el-option label="19" value="19"></el-option>
             <el-option label="20" value="20"></el-option>
            </el-select>
            -
            <el-select
              v-model="ruleForm.maxSalary"
              style="width: 100px"
            >
             <el-option label="4" value="4"></el-option>
             <el-option label="5" value="5"></el-option>
             <el-option label="6" value="6"></el-option>
             <el-option label="7" value="7"></el-option>
             <el-option label="8" value="8"></el-option>
             <el-option label="9" value="9"></el-option>
             <el-option label="10" value="10"></el-option>
             <el-option label="11" value="11"></el-option>
             <el-option label="12" value="12"></el-option>
             <el-option label="13" value="13"></el-option>
             <el-option label="14" value="14"></el-option>
             <el-option label="15" value="15"></el-option>
             <el-option label="16" value="16"></el-option>
             <el-option label="17" value="17"></el-option>
             <el-option label="18" value="18"></el-option>
             <el-option label="19" value="19"></el-option>
             <el-option label="20" value="20"></el-option>
             <el-option label="25" value="25"></el-option>
             <el-option label="30" value="30"></el-option>
             <el-option label="35" value="35"></el-option>
             <el-option label="40" value="40"></el-option>
            </el-select>K
          </el-form-item> 
           <el-form-item label="具体要求" prop="requirement">
            <el-input
              v-model="ruleForm.requirement"
              type="textarea"
              size="medium"
              style="width: 464px; font-size：16px"
              :rows="5"
              placeholder="请输入"
            >
            </el-input>
          </el-form-item> 
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine" v-if="bdetermine"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="选择商品"
      :visible.sync="dialogDetailVisible"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      width="50%"
      center
    >
      <commonTable
        :tableData="goodsList"
        :loading="goodsListLoading"
        @handleSizeChange="goodsListSizeChange"
        @handleCurrentChange="goodsListCurrentChange"
        @handleSelectionChange="handleSelectionChange"
        :currentPage="goodsListCurrentPage"
        :total="goodsListTotal"
      >
        <template v-slot:table>
          <el-table-column align="center" type="selection"></el-table-column>
          <el-table-column
            prop="goodsId"
            align="center"
            label="产品id"
            show-overflow-tooltip
          />
          <el-table-column prop="goodsName" align="center" label="产品 " />
          <el-table-column prop="goodsTypeName" align="center" label="分类" />
          <el-table-column prop="price" align="center" label="价格" />
          <el-table-column prop="stockNum" align="center" label="库存" />
          <el-table-column prop="dealNum" align="center" label="总销量" />
          <el-table-column prop="createTime" align="center" label="发布时间">
            <template slot-scope="scope">
              {{ scope.row.createTime | getDataTimeSec }}
            </template>
          </el-table-column>
        </template>
      </commonTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDetailVisible = false">取 消</el-button>
        <el-button @click="queding" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { mapState } from 'vuex';
import customPopconfirm from "@/components/common/customPopconfirm";
import {selectCompanyRecruit,addCompanyRecruit,delCompanyRecruit} from '@/api/companyManage.js'
import {
  queryPage,
  add,
  deleteById,
  updateData,
  update,
  getGoodsInfoPC,
} from "@/api/videoManage";
import { getDataTimeSec } from "@/utils";
let ac;
export default {
  data() {
    return {
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      goodsList: [],
      goodsListCurrentPage: 1, //当前页
      goodsListPageSize: 10, //显示条数
      goodsListLoading: false, //表格加载
      goodsListTotal: 0, //总条数
      centerDialogVisible: false,
      dialogDetailVisible: false,
      bEdit: false,
      bdetermine: true,
      editUpdate:true,
      isUpdate:true,
      fileList: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      ruleForm: {
        position: "",
        recruitAddress: "",
        experience: "",
        experienceStr:"",
        educationStr:"",
        education: "",
        maxSalary: "",
        minSalary: "",
        requirement: "",
      },
      rules: {
        position: [{ required: true, message: "请输入职位", trigger: "blur" }],
        recruitAddress: [
          { required: true, message: "请输入添加位置", trigger: "blur" },
        ]
      },
      jurisdictionA:'',
      jurisdictionB:'',
      experienceArr:['应届','1年内','1-3年','3-5年','5-10年','10年以上','全部'],
      
      experienceArray:[{value:'1',label:'应届'},{value:'2',label:'1年内'},{value:'3',label:'1-3年'},{value:'4',label:'3-5年'},{value:'5',label:'5-10年'},{value:'6',label:'10年以上'},{value:'7',label:'全部'}],
      educationArray:[{value:'1',name:'全部'},{value:'2',name:'初中及以下'},{value:'3',name:'中专/中技'},{value:'4',name:'高中'},{value:'5',name:'大专'},{value:'6',name:'本科'},{value:'7',name:'硕士'},{value:'8',name:'博士'}],
      educationArr:['全部','初中及以下','中专/中技','高中','大专','本科','硕士','博士']
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  filters: {
    getDataTimeSec(timeStamp) {
      var dateTime = new Date(timeStamp);
      var no1new = dateTime.valueOf();
      var year = dateTime.getFullYear();
      var month = dateTime.getMonth() + 1;
      var day = dateTime.getDate();
      var hour = dateTime.getHours();
      var minute = dateTime.getMinutes();
      var second = dateTime.getSeconds();
      var now = new Date();
      var now_new = now.valueOf();
      var milliseconds = 0;
      var timeSpanStr;
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }

      milliseconds = now_new - no1new;

      if (milliseconds <= 1000 * 60 * 1) {
        timeSpanStr = "刚刚";
      } else if (
        1000 * 60 * 1 < milliseconds &&
        milliseconds <= 1000 * 60 * 60
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60)) + "分钟前";
      } else if (
        1000 * 60 * 60 * 1 < milliseconds &&
        milliseconds <= 1000 * 60 * 60 * 24
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + "小时前";
      } else if (
        1000 * 60 * 60 * 24 < milliseconds &&
        milliseconds <= 1000 * 60 * 60 * 24 * 15
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + "天前";
      } else if (
        milliseconds > 1000 * 60 * 60 * 24 * 15 &&
        year == now.getFullYear()
      ) {
        timeSpanStr = year + "-" + month + "-" + day;
      } else {
        timeSpanStr = year + "-" + month + "-" + day;
      }
      return timeSpanStr;
    },
  },
  computed: {
    ...mapState(['routerArr']),
    uploadDisabled() {
      return this.ruleForm.coverImg !== "";
    },
  },
  watch:{
    routerArr(val){
      console.log(val)
      this.jurisdictionA = val.includes('080')
      this.jurisdictionB = val.includes('081')
      if(this.jurisdictionA || this.jurisdictionB){
        this.selectCompanyRecruit()
      }
    }
  },
  created() {

    this.getGoodsInfoPC();
  },
  mounted(){
    this.selectCompanyRecruit();
  },
  beforeDestroy() {
    ac.removeEventListener("onConfirm", this.setValue);
  },
  methods: {
    async selectCompanyRecruit() {

      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        queryType: 'web' ,
      };
      if(this.jurisdictionA){
        data.queryType = 'web'
        if(this.jurisdictionB){
          data.queryType = ''
        }
      }else if(this.jurisdictioB){
        data.queryType = ''
      }

      try {
        this.loading = true;
        const result = await selectCompanyRecruit(data);

        this.loading = false;
        // const { total, list } = result.data;
        this.tableData = result.data;
        
        var newList = []
        this.tableData.forEach(item =>{
            item.maxSalary=item.maxSalary/100000
            item.minSalary=item.minSalary/100000
            item.salary=item.minSalary+'k-'+item.maxSalary+'k'
            item.experienceStr=this.experienceArr[item.experience-1]
            item.educationStr=this.educationArr[item.education-1]
            newList.push(item)
        })
        console.log(newList)
        this.tableData=newList
        this.total = total;
      } catch (error) {
        this.loading = false;
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.coverImg = "";
    },
    /**@method 加载商品列表 */
    async getGoodsInfoPC() {
      let data = {
        pageSize: this.goodsListPageSize,
        pageNum: this.goodsListCurrentPage,
      };

      try {
        this.goodsListLoading = true;
        const result = await getGoodsInfoPC(data);
        this.goodsListLoading = false;
        const { total, list } = JSON.parse(result?.data?.pageInfo);
        this.goodsList = list;
        this.goodsListTotal = total;
      } catch (error) {
        this.goodsListLoading = false;
      }
      // const reslut
    },
    /**@method 模态框打开时的回调 */
    openDialog() {
      let map = new BMap.Map("baidumap");
      ac = new BMap.Autocomplete({
        //建立一个自动完成的对象
        input: "suggestId",
        location: map,
      });
      ac.addEventListener("onConfirm", this.setValue);
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        position: "",
        recruitAddress: "",
        experience: "",
        education: "",
        maxSalary: "",
        minSalary: "",
        requirement: "",
      }),
        (this.fileList = []);
      this.bEdit = false;
    },
    setValue(e) {
      const {
        item: { value },
      } = e;
      this.ruleForm.address = `${value.city}${value.district}${value.business}`;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.selectCompanyRecruit();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.selectCompanyRecruit();
    },
    goodsListSizeChange(val) {
      this.goodsListPageSize = val;
      this.getGoodsInfoPC();
    },
    goodsListCurrentChange(val) {
      this.goodsListCurrentPage = val;
      this.getGoodsInfoPC();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    goodsDelete(index){
      this.ruleForm.videoGoods.splice(index,1)
    },
    queding() {
      this.ruleForm.videoGoods = [];
      this.multipleSelection.forEach((item) => {
        let data = {
          companyId: item.companyId,
          createdTime: item.createTime,
          goodsId: item.goodsId,
          videoGoodId: item.goodsId,
          videoId: this.ruleForm.videoId,
          name: item.goodsName,
          price: (item.price / 100).toFixed(2),
        };
        this.ruleForm.videoGoods.push(data);
        this.dialogDetailVisible = false;
      });
    },
    /**@method 上传时回调 */
    handProgress(event, file, fileList) {
      if(this.isUpdate){
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;

    },
    /**@method 上传成功 */
    handleAvatarSuccessVideo(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.isUpdate = true;
      this.ruleForm.url = `https://xcd-stand.oss-cn-beijing.aliyuncs.com/${res.data}`;
    },
    beforeAvatarUploadVideo(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 10MB!");
      }
      return isLt2M;
    },
    handleAvatarSuccessCoverImg(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverImg = `https://xcd-stand.oss-cn-beijing.aliyuncs.com/${res.data}`;
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val, b) {
      let data = JSON.parse(JSON.stringify(val));
      this.centerDialogVisible = true;
      this.ruleForm = data;
      // this.ruleForm.educationStr=this.educationArr[this.ruleForm.education-1]
      // this.ruleForm.experienceStr=this.experienceStr[this.ruleForm.experience-1]
      console.log(this.ruleForm);
     
    },
    /**@method 添加 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
          //  this.ruleForm.experience=this.experienceArr.map(item => item).indexOf(this.ruleForm.experienceStr)+1
            //  this.ruleForm.education=this.educationArr.map(item => item).indexOf(this.ruleForm.educationStr)+1
            var pattern = new RegExp("[\u4E00-\u9FA5]+");
            if(!pattern.test(this.ruleForm.experienceStr)){
              this.ruleForm.experience=this.ruleForm.experienceStr
            }
            if(!pattern.test(this.ruleForm.educationStr)){
              this.ruleForm.education=this.ruleForm.educationStr
            }
           console.log(this.ruleForm)
        if (valid) {
          try {
            if (this.ruleForm.suggestions == "") {
              delete this.ruleForm.suggestions;
            }
            let data = {
              ...this.ruleForm,
              link: "",
            };
            data.maxSalary=data.maxSalary*100000
            data.minSalary=data.minSalary*100000
            this.$message({
              message: "正在保存",
            });
            if (this.editUpdate) {
              await addCompanyRecruit(data);
            } else {
              await addCompanyRecruit(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.close()
            await this.selectCompanyRecruit();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delCompanyRecruit({ recruitId: val });
        await this.selectCompanyRecruit();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 更新 */
    async addCompanyRecruit(val) {
      let data = {
        videoId: val.videoId,
        state: val.state ? 0 : 1,
      };
      await addCompanyRecruit(data);
      await this.selectCompanyRecruit();
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 180px;
  text-align: center;
}
/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}
.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}
.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>
